@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

/* bullets amarillos para slider testimonios */
@layer utilities {
  .custom-swiper .swiper-pagination-bullet {
    @apply bg-[#FFB71B];
  }

  .custom-swiper .swiper-pagination-bullet-active {
    @apply bg-[#FFB71B];
  }
}

/* Servicios */
.box-shadow {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

/* Contacto */
.select-default-option {
  color: #aaaaaa;
}

/* Animaciones */
/* Home */
/* Especifico para desktop para que no se borre la transparencia de los lados */
/* 1 */
[data-aos="color-change-1"] {
  border-color: #ffb71b;
  border-top: transparent;
  border-right: transparent;
  /* eslint-disable-next-line */
  &.aos-animate {
    border-color: #f18638;
  }
}
/* 3 */
[data-aos="color-change-3"] {
  border-color: #ffb71b;
  border-top: transparent;
  border-right: transparent;
  /* eslint-disable-next-line */
  &.aos-animate {
    border-color: #f18638;
  }
}

/* 2 y 4 */
[data-aos="color-change-2"] {
  border-color: #ffb71b;
  border-bottom: transparent;
  border-right: transparent;
  /* eslint-disable-next-line */
  &.aos-animate {
    border-color: #f18638;
  }
}

[data-aos="color-change-bg-desk"] {
  color: #ffb71b;
  /* eslint-disable-next-line */
  &.aos-animate {
    color: white;
  }
}

/* El resto */

[data-aos="color-change-line"] {
  border-color: #ffb71b;
  /* eslint-disable-next-line */
  &.aos-animate {
    border-color: #f18638;
  }
}

[data-aos="color-change-bg"] {
  background-color: white;
  color: #ffb71b;
  /* eslint-disable-next-line */
  &.aos-animate {
    background-color: #f18638;
    color: white;
  }
}

[data-aos="color-change-circle"].aos-animate {
  background-color: #f18638;
}
